<template>
    <div class="container">
      <div class="content">
        <div class="title">
          Payment Confirmation
        </div>
        <div class="intro">
          <div class="item">
            <div class="item1">Project Name</div>
            <div class="item2">{{application.application_project}}</div>
          </div>
          <div class="item">
            <div class="item1">Entry Name</div>
            <div class="item2">{{application.entry_info?application.entry_info.name:''}}</div>
          </div>
          <div class="item" style="margin-bottom:0">
            <div class="item1">Payment Amount</div>
            <div class="item3">SGD${{orderData.amount}}</div>
          </div>
        </div>
        <div class="title1">
          Terms & Conditions
        </div>
        <div class="intro1">
          {{application.project_refund_policy}}
        </div>
        <div class="tip">
          <img v-if="check" src="../assets/select2.svg" @click="check=false">
          <img v-else src="../assets/select1.svg"  @click="check=true">
          I have read and agree on the terms and conditions above.
        </div>
        <div class="btnGroup">
          <div class="cancel" @click="$router.back(-1)">Cancel</div>
          <div class="pay" @click="toPay">Pay</div>
        </div>
      </div>
      <el-dialog  :visible.sync="showPayList"
        width="813px"
        height="831px"
        center
        custom-class="dialog_class"
        close-on-click-modal
        close-on-press-escape
        title="Payment Method List">
        <div style="text-align:center">
          <PayPal v-if="showPay" :amount="orderData.amount" :currency="orderData.currency_code" :client="credentials"
            :button-style="buttonStyle" @payment-authorized="paymentAuthorized"
            @payment-completed="paymentCompleted" @payment-cancelled="paymentCancelled">
          </PayPal>
        </div>

      </el-dialog>
    </div>
  </template>

<script>
import { getApplicationByID, getPaypal, paypalSucceedBack } from '../api/index'
import '../assets/common/font.css'
import PayPal from 'vue-paypal-checkout'
export default {
  components: {
    PayPal
  },
  data () {
    return {
      credentials: {
        sandbox: '',
        production: ''
      },
      showPay: false,
      showPayList: false,
      orderData: {},
      buttonStyle: {
        label: 'pay',
        size: 'small',
        shape: 'rect',
        color: 'blue'
      },
      id: this.$route.query.id,
      application: {},
      check: false
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      getApplicationByID(this.id).then((res) => {
        if (res.data.code === 0) {
          this.application = res.data.data
        }
      })
      getPaypal(this.id).then((res) => {
        res.data.data.amount = String(res.data.data.amount)
        this.orderData = res.data.data
        this.credentials.sandbox = res.data.data.client_id
        this.credentials.production = res.data.data.live_client_id
        console.log(222, this.orderData)

        this.showPay = true
      })
    },
    paymentAuthorized (data) {
      // 授权完成的回调，可以拿到订单id
      console.log('Authorization Complete', data)
    },

    paymentCompleted (data) {
      // 用户支付完成的回调，可以拿到订单id
      console.log('Payment Complete', data)
      this.paypalSucceedBack(data)
    },

    paymentCancelled (data) {
      // 用户取消交易的回调
      console.log('Payment Cancelled', data)
    },

    paypalSucceedBack (data) {
      paypalSucceedBack(this.id, data).then(res => {
        if (res.data.code === 0) {
          this.$message({
            message: 'Payment Success!',
            type: 'success'
          })

          getApplicationByID(this.id).then((res) => {
            if (res.data.code === 0) {
              this.application = res.data.data
            }
          })
        }
      })
    },
    toPay () {
      if (!this.check) {
        this.$message.error('Please check to agree on the terms and conditions first')
        return
      }
      this.showPayList = true
    }
  }
}
</script>

  <style lang="scss" scoped>
  .container {
    width: 100%;
    height: 100vh;
    background: url('../assets/applyBack.png');
    background-size: 110% 120%;
    background-position: 10% 10%;
      font-family: "DDINAlternateRegular";
    .content {
      width: 1200px;
      margin: 0 auto;
      height: 100%;
      background: #fff;
      padding: 80px;
      box-sizing: border-box;
      overflow-x: scroll;
      .title{
        font-size: 24px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #F65D4D;
        text-decoration:underline
      }
      .title1{
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #333333;
        font-size: 20px;
        margin-top: 30px;
      }
      .intro{
        background: #F5F9FF;
        width: 100%;
        padding: 40px 60px;
        box-sizing: border-box;
        margin-top: 30px;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
      .intro1{
        background: #F5F9FF;
        width: 100%;
        padding: 40px 60px;
        box-sizing: border-box;
        margin-top: 30px;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 34px;
        white-space: pre-wrap;
      }
      .item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        .item1{
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #333333;
          font-size: 18px;
        }
        .item2{
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #0E4890;
          font-size: 20px;
        }
        .item3{
          font-size: 24px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #F65D4D;
        }
      }
      .tip{
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #333333;
          margin-top: 20px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            margin-right: 6px;
            cursor: pointer;
          }
      }

      .btnGroup{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
        .cancel{
          width: 180px;
          height: 40px;
          background: #0E4890;
          border-radius: 25px;
          color: #fff;
          line-height: 40px;
          text-align: center;
          cursor: pointer;
        }
        .pay{
          width: 180px;
          height: 40px;
          background: #FFB414;
          border-radius: 25px;
          color: #fff;
          line-height: 40px;
          text-align: center;
          cursor: pointer;
          margin-left: 30px;
        }
      }

    }
  }
  </style>
